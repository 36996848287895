import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

function truncate(str) {
  return str.length > 30 ? str.substring(0, 30) + "..." : str;
}

const BlogCategory = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const res = data.allWpCategory.nodes
 
  if (!res.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="All posts" />

      {/* <Bio /> */}
      <div className="flex flex-wrap ">
        {res.map(function(post, i) {
            const title = post.name
            const catPosts = res[i].posts.nodes

            const hasPosts = catPosts.map(function(cat, k) {
              return cat.title
            })

            const el = Array.from(catPosts)[0];
            const featuredImage = el?.featuredImage?.node?.guid
            const postDate = el?.date

            const chapters = catPosts.slice(0, 3).map(cat => {
                return <a href={cat.uri} className='block' style={{overflow: 'hidden', textOverflow: 'ellipsis'}}> {truncate(cat.title)} </a>
            }); 

            if (hasPosts.length) {
              return (
                <div className="flex p-2 sm:w-1/2">
                  
                  <div className="mr-4 mb-2 flex-shrink-0">
                    {featuredImage
                      ? <img
                          src={featuredImage}
                          className="h-full w-24 border border-gray-300 bg-white text-gray-300"
                          alt={parse(title)}
                        />
                      : 
                        <svg
                          className="h-full w-24 border border-gray-300 bg-white text-gray-300"
                          preserveAspectRatio="none"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 200 200"
                          aria-hidden="true"
                        >
                          <path vectorEffect="non-scaling-stroke" strokeWidth={1} d="M0 0l200 200M0 200L200 0" />
                        </svg>
                    }
                  </div>
                  <div className="">
                    <h4 className="text-md hover:text-indigo-700 font-bold">
                      <Link to={post.uri} itemProp="url">
                        <span itemProp="headline">{parse(title)}</span>
                      </Link>
                    </h4>
                    <p className="text-xs text-gray-400"><i>{postDate}</i></p>
                    <p className="mt-1 text-xs">
                      {chapters}
                    </p>
                  </div>
                </div>
                )
            } else { return(<></>)}
        })}
      </div>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </Layout>
  )
}

export default BlogCategory

export const pageQuery = graphql`
  query WordPressPostCategory($offset: Int!, $postsPerPage: Int!) {
    allWpCategory(
      sort:  { fields: [name], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        name
        slug
        uri
        posts {
          nodes {
            title
            date(formatString: "MMMM DD, YYYY")
            uri
            featuredImage {
              node {
                guid
              }
            }
          }
        }
      }
    }
  }
`
